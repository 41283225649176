.footer {
  position: relative;
  display: flex;
  margin: 0 auto;
  flex-direction: row;
  justify-content: left;
  border-top: 1px solid;
  width: 760px;
  margin-top: 100px;
  color: $light-color-secondary;  
  
  a {
    color: #1a95e0;
    text-decoration: none;
  }

  &__content {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 0.8rem;
    color: $light-color-secondary;
  }
}
